@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flossy-picker {
  height: 300px;

  .react-datepicker {
    border: none !important;
    border-radius: 8px !important;
  }

  .react-datepicker__header {
    background: transparent !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom: 1px solid #b5c2cf;
  }

  .react-datepicker-time__header,
  .react-datepicker__header--time {
    color: #444;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 8px 0 8px 0;
  }

  .react-datepicker__current-month {
    color: #444;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .react-datepicker__day-name {
    color: #444 !important;
    font-family: 'Helvetica Neue' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    margin-top: 12px !important;
    margin-bottom: 8px !important;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
  }

  .react-datepicker__month-container {
    border-radius: 8px;
    border: 1px solid #b5c2cf;
    background: #fff;
    width: 300px;
  }

  .react-datepicker--time--only {
    border-left: 1px solid #b5c2cf !important;

    .react-datepicker__time-container {
      border-left: 1px solid #b5c2cf !important;
    }

    .react-datepicker__time-container {
      border-left: 1px solid #b5c2cf !important;
    }
  }

  .react-datepicker__time-container {
    border-radius: 8px;
    border: 1px solid #b5c2cf !important;
    background: transparent !important;

    .react-datepicker__time {
      background: transparent !important;
    }

    .react-datepicker__time-box {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;

      /* Hide scrollbar for Chrome, Safari and Opera */
      ::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for Firefox */
      scrollbar-width: none;
    }

    .react-datepicker__time-list {
      background: transparent !important;
      height: 220px !important;
    }

    .react-datepicker__time-list-item {
      color: #444;
      font-family: 'Helvetica Neue';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
